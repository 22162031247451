import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

import ParseContent from '../shared/ParseContent'
import { mainTheme } from '../../styles/Theme'

const Wrapper = styled.div`
  overflow: hidden;
  .cursor-pointer {
    cursor: pointer;
  }

  & span {
    &:hover {
      text-decoration: underline;
    }
  }
`

const Arrow = styled.div`
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;

  border-left: 10px solid ${(props) => props.theme.color.contrast};
  margin-left: 40px;
  margin-right: 10px;

  ${(props) =>
    props.isOpen &&
    css`
      transform: rotate(90deg);
    `};

  @media (max-width: 991.98px) {
    margin-left: 15px;
  }
`

const Content = styled.div`
  border-left: 1px solid ${(props) => props.theme.color.contrast};
  padding-left: 25px;
  margin-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 25px;

  @media (max-width: 991.98px) {
    margin-left: 0px;
    padding-right: 0;

    & iframe {
      height: 179px;
    }
  }

  @media (min-width: 992px) {
    & iframe {
      min-width: 749px;
    }
  }

  & a:hover {
    color: ${(props) => props.theme.color.text.contrast};
    text-decoration: underline;
  }
`

function Accordion({ title, content }) {
  const [isOpen, setOpen] = useState(false)

  return (
    <Wrapper>
      <motion.div
        initial={false}
        className="d-flex align-items-center justify-content-between cursor-pointer"
        animate={{
          color: isOpen
            ? mainTheme.color.text.contrast
            : mainTheme.color.text.main,
          fontWeight: isOpen ? 'bold' : 'normal',
        }}
        onClick={() => setOpen(!isOpen)}
      >
        <span className="font-size-xm font-family-main py-3">{title}</span>
        <Arrow isOpen={isOpen} />
      </motion.div>

      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: {
                opacity: 1,
                height: 'auto',
                marginBottom: '35px',
                marginTop: '25px',
              },
              collapsed: {
                opacity: 0,
                height: 0,
                marginBottom: 0,
                marginTop: 0,
              },
            }}
            transition={{ duration: 0.3 }}
          >
            <Content className="d-flex flex-wrap">
              <ParseContent content={content} />
            </Content>
          </motion.div>
        )}
      </AnimatePresence>
    </Wrapper>
  )
}

export default Accordion
