/* eslint-disable react/function-component-definition */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import Accordion from 'components/elements/Accordion'

const AccordionWrapper = styled.div``

const Questions = ({ questIds }) => {
  const { questionList } = useStaticQuery(graphql`
    {
      questionList: allWpVraag {
        edges {
          node {
            id
            title
            content
          }
        }
      }
    }
  `)

  let questionPosts = questionList.edges

  if (questIds && questIds.length > 0) {
    questionPosts = questIds
  }

  return (
    <div className="w-100">
      <AccordionWrapper>
        {questIds && questIds.length > 0
          ? questionPosts.map(({ title, content, id }) => (
              <div key={id}>
                <Accordion title={title} content={content} />
                <hr className="w-100" />
              </div>
            ))
          : questionPosts.map(({ node: { title, content, id } }) => (
              <div key={id}>
                <Accordion title={title} content={content} />
                <hr className="w-100" />
              </div>
            ))}
      </AccordionWrapper>
    </div>
  )
}

export default Questions
