import React from 'react'
import styled from 'styled-components'
import BoxDefault from './BoxDefault'
import ParseContent from '../shared/ParseContent'
import ButtonDefault from './ButtonDefault'

const Diensten = styled.section`
  .grid {
    position: relative;
    z-index: 2;
  }

  & h2 {
    color: #d8d8d8;
    font-weight: bold;
  }

  @media (min-width: 992px) {
    & h2 {
      font-size: 250px;
      margin-left: -5rem;
    }

    .grid {
      margin-top: -6rem;
    }

    padding-bottom: 6rem;
  }

  @media (max-width: 991px) {
    & h2 {
      font-size: 150px;
    }
    margin-bottom: 3rem;
  }

  @media (max-width: 575.98px) {
    & h2 {
      font-size: 80px;
    }
  }

  & p {
    text-align: justify !important;
  }
`

const Dienst = styled(BoxDefault)`
  background: transparent;

  a,
  > .used-to-be-link {
    background-color: #e5e5e5;
    padding: 1rem;
    color: #1c1c1c;

    h3 {
      font-size: 20px;
      font-weight: bold;
      padding-inline: 1rem;
    }
  }

  &:after {
    right: 0;
    bottom: 0;
    border-top-width: 50px;
    border-right-width: 50px;
  }

  aspect-ratio: 1/1;

  @media (min-width: 992px) {
    img {
      height: 90px;
      width: auto;
      margin-bottom: 1rem;
    }
  }

  @media (max-width: 991.98px) {
    img {
      height: 45px;
      width: auto;
    }

    > a h3 {
      font-size: 16px;
    }
  }

  @media (max-width: 575px) {
    padding: 0 !important;
  }
`

const Title = styled.h3`
  @media (max-width: 400px) {
    font-size: 16px !important;
  }
`

function ServicesInline({ services }) {
  return (
    <Diensten>
      <div className="container h-100 d-flex flex-column">
        <h2>{services.title}</h2>
        <div className="row h-100">
          {services.items.map((edge) => (
            <div className="col-6 col-lg-3 h-100 mb-4">
              <Dienst>
                <div className="used-to-be-link d-flex flex-column justify-content-center h-100 align-items-center">
                  <img src={edge.icon.localFile.publicURL} alt="" />
                  <Title className="text-center">
                    <ParseContent content={edge.link.title} />
                  </Title>
                </div>
              </Dienst>
            </div>
          ))}
        </div>
        <div className="row mt-5">
          <div className="col-lg-8 pr-5">
            <ParseContent content={services.cta.description} />
          </div>
          <div className="col-lg-4">
            <ButtonDefault to={services.cta.link.url}>
              {services.cta.link.title}
            </ButtonDefault>
          </div>
        </div>
      </div>
    </Diensten>
  )
}

export default ServicesInline
