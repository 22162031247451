/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from './ButtonShell'

import ChevronRightIcon from '../../img/icons/chevron-right-red.svg'

const StyledButton = styled.div`
  display: inline-block;
  position: relative;
  font-size: 20px;
  font-weight: 300;
  transition: transform 0.25s ease 0s;

  &:hover {
    transform: scale(1.05);
  }

  &::after {
    content: '';
    display: block;
    width: 20px;
    height: 21px;
    position: absolute;
    right: -8px;
    bottom: -9px;
    transform: rotate(45deg);
    background: #fff;
    border-left: 2px solid ${(props) => props.theme.color.text.contrast};
  }

  & > a,
  & > button {
    text-decoration: unset;
    background-color: transparent;
    border: 2px solid ${(props) => props.theme.color.contrast};
    color: ${(props) => props.theme.color.text.contrast};
    padding: 10px 15px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 0;
    position: relative;

    &::after {
      content: '';
      display: block;
      background-image: url(${ChevronRightIcon});
      background-size: contain;
      width: 14px;
      height: 18px;
      background-repeat: no-repeat;
      margin-left: 20px;
      margin-right: 5px;
    }
  }

  ${(props) =>
    props.back &&
    css`
      &::after {
        content: '';
        display: block;
        width: 20px;
        height: 21px;
        position: absolute;
        left: -8px;
        bottom: -9px;
        transform: rotate(-45deg);
        background: #fff;
        border-left: unset;
        border-right: 2px solid ${props.theme.color.text.contrast};
      }

      & > a,
      & > button {
        padding-left: 0 !important;
        padding-right: 15px !important;

        &::before {
          content: '';
          display: block;
          background-image: url(${ChevronRightIcon});
          background-size: contain;
          width: 14px;
          height: 18px;
          background-repeat: no-repeat;
          margin-left: 5px;
          margin-right: 20px;
          transform: rotate(180deg);
        }

        &::after {
          display: none;
        }
      }
    `}
`

function ButtonDefaultOutline({
  isAnchor,
  isCustom,
  to,
  children,
  className,
  back,
}) {
  return (
    <StyledButton back={back} className={className}>
      {isCustom ? (
        children
      ) : (
        <ButtonShell to={to} isAnchor={isAnchor}>
          {children}
        </ButtonShell>
      )}
    </StyledButton>
  )
}

export default ButtonDefaultOutline
