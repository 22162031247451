/* eslint-disable react/function-component-definition */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Libraries
import queryString from 'query-string'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ParseContent from 'components/shared/ParseContent'
import chevronRight from 'img/icons/chevron-right.svg'
import ButtonDefaultOutline from '../components/elements/ButtonDefaultOutline'
import BoxDefaultOutline from '../components/elements/BoxDefaultOutline'
import Hero from '../components/elements/Hero'
import number1 from '../img/numbers/1.svg'
import number2 from '../img/numbers/2.svg'
import number3 from '../img/numbers/3.svg'
import Questions from '../components/Questions'
import HeroIntro from '../components/elements/HeroIntro'
import Concepts from '../components/elements/Concepts'
import ServicesInline from '../components/elements/ServicesInline'
import QuoteVideo from '../components/elements/QuoteVideo'
import Projects from '../components/elements/Projects'
// import ClosedModal from '../components/elements/ClosedModal'

const Identiteit = styled.div`
  ul {
    padding-left: 25px;
    margin-bottom: 0;
    list-style-type: none;

    & li {
      position: relative;

      :before {
        content: '';
        background: url(${chevronRight});
        height: 9px;
        width: 9px;
        top: 11px;
        left: -25px;
        position: absolute;
        transform: rotate(225deg);
      }
    }
  }

  img {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  h3 {
    line-height: 1;
  }

  > div:nth-child(1) {
    width: 60%;
  }

  > div:nth-child(2) {
    width: 80%;
  }

  @media (max-width: 991.98px) {
    ul {
      font-size: 16px;
    }

    > div:nth-child(1) {
      width: 100%;
    }

    > div:nth-child(2) {
      width: 100%;
    }
  }
`

const LogoSVG = styled.svg`
  @media (max-width: 991.98px) {
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    height: auto;
    margin-bottom: 35px;
  }
`

const Welcome = styled.div`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const WelcomeMessage = styled.div`
  background-color: #ffffff;
  max-width: 700px;
  min-height: 100px;
  padding: 30px;

  & > h1 {
    font-size: ${(props) => props.theme.font.size.xl};
  }

  & > h2 {
    font-size: ${(props) => props.theme.font.size.l};
  }

  & > p {
    font-size: ${(props) => props.theme.font.size.sm};
  }
`

const WelcomeButton = styled.button`
  font-size: ${(props) => props.theme.font.size.m};
  background-color: ${(props) => props.theme.color.contrast};
  color: ${(props) => props.theme.color.text.secondary};
  height: 40px;
  width: 150px;
`

const IdentiteitBG = styled(Plaatjie)`
  height: 809px;

  @media (max-width: 991px) {
    height: 992px;
  }
`

const StyledBoxDefaultOutline = styled(BoxDefaultOutline)`
  position: relative;

  .border-right {
    position: absolute;
    right: 0;
    top: 0;
    width: 2px;
    background-color: #fff;
    height: 75%;
  }

  .border-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 92.5%;
    background-color: #fff;
    height: 2px;
  }

  .border-diagonal {
    position: absolute;
    bottom: 10%;
    right: -4.3%;
    width: 17.5%;
    height: 2px;
    background-color: #fff;
  }

  @media (min-width: 992px) {
    border-bottom: 0;
    border-right: 0;
  }

  @media (max-width: 991.98px) {
    .border-right,
    .border-bottom,
    .border-diagonal {
      display: none;
    }
  }
`

const Faq = styled.div`
  .special-button {
    padding: 0 !important;
    max-width: 250px;
    margin-top: 3rem;

    & a {
      font-size: 17px;
      font-weight: 500;
    }
  }

  @media (min-width: 992px) {
    & h2 {
      font-size: 50px;
    }

    > div {
      padding-inline: 7rem;
    }
  }
`

const HomeTemplate = ({
  location,
  data: {
    page: {
      pageHome: {
        hero,
        video,
        projecten,
        identiteit,
        vragen,
        overons,
        concepten,
        diensten,
      },
      seo,
    },
  },
}) => {
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false)

  useEffect(() => {
    if (typeof location !== 'undefined' && location.hash) {
      const parsedHash = queryString.parse(location.hash)

      const showWelcomeMessageInitial =
        parsedHash && Object.keys(parsedHash).indexOf('welkom') !== -1

      if (showWelcomeMessageInitial && typeof localStorage !== 'undefined') {
        const showWelcomeMessageInitialLocalStorage =
          localStorage.getItem('welcome-message')

        if (showWelcomeMessageInitialLocalStorage) {
          if (showWelcomeMessageInitialLocalStorage === 'false') {
            setShowWelcomeMessage(false)
          } else {
            setShowWelcomeMessage(true)
          }
        } else {
          setShowWelcomeMessage(true)
        }
      }
    }
  }, [])

  const hideWelcomeMessage = () => {
    setShowWelcomeMessage(false)

    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('welcome-message', 'false')
    }
  }

  return (
    <Layout subFooter>
      {seo && <SEO seo={seo} />}

      <Welcome show={showWelcomeMessage}>
        <div className="container">
          <WelcomeMessage>
            <h1>FM Bouw heet voortaan C3 Staalframebouw</h1>
            <h2>Welkom op onze vernieuwde website</h2>
            <p className="mt-4 text-justify">
              Alleen onze naam en uitstraling zijn veranderd. Wij zijn nog
              steeds uw specialist in staalframebouw. Met onze constructieve
              totaaloplossingen faciliteren wij het gehele proces van cascobouw
              – van engineering tot realisatie.
            </p>
            <div className="mt-4 text-center">
              <WelcomeButton type="button" onClick={hideWelcomeMessage}>
                Ok, duidelijk
              </WelcomeButton>
            </div>
          </WelcomeMessage>
        </div>
      </Welcome>

      <Hero title={hero.title} src={hero.image} />

      <HeroIntro
        title={overons.title}
        description={overons.description}
        titleunder={hero.titleunder}
        link={hero.link}
      />

      <Concepts
        title={concepten.title}
        blockLeft={concepten.blockleft}
        blockRight={concepten.blockright}
      />

      <ServicesInline services={diensten} />

      <QuoteVideo video={video} />

      <Projects projects={projecten} />

      <section className="position-relative">
        <IdentiteitBG
          image={identiteit.background}
          className="position-absolute"
        />
        <div className="container py-5">
          <div className="row align-items-center justify-content-center mb-5 pb-4">
            <div className="col-lg-4 d-lg-block d-flex justify-content-center">
              <LogoSVG
                xmlns="http://www.w3.org/2000/svg"
                width="287"
                height="369.5"
                viewBox="0 0 287 369.5"
              >
                <text
                  textAnchor="middle"
                  fill="#d50032"
                  fontFamily="Montserrat"
                  fontSize="50"
                  fontStyle="italic"
                  transform="translate(125 -1594) translate(25 1594)"
                >
                  <tspan x="0" y="48">
                    KRACHT{' '}
                  </tspan>
                  <tspan x="0" y="103">
                    {' '}
                    VAN
                  </tspan>
                </text>
                <path
                  fill="none"
                  stroke="#fefefe"
                  strokeMiterlimit="10"
                  strokeWidth="7"
                  d="M296.413 1649.24L269 1676.314V1960h200.237L549 1883.683V1600h-13.9"
                  transform="translate(-265.5 -1594)"
                />
                <path
                  fill="#fff"
                  d="M23.732 0L0 23.383V176h61.5L86 152.616v-11.96H61.5v11.961h-49l12-11.817V23.383h37V35.2H86V0z"
                  transform="translate(-265.5 -1594) translate(356 1722) translate(.5)"
                />
                <path
                  fill="#d50032"
                  d="M67.789 42.757l-6.136 6.142v6.012H91.29v25.484H79.54l-6.79 6.142v6.142h19.716v29.4h-25.33L61 128.095v6.142h31.858L105 122.084v-30.45l-5.875-5.751 4.309-4.313V42.757z"
                  transform="translate(-265.5 -1594) translate(356 1722) translate(.5)"
                />
              </LogoSVG>
            </div>
            <div className="col-lg-8">
              <Identiteit>
                {identiteit.blokken.map((blok, key) => {
                  let deg = 47
                  if (key === 1) {
                    deg = 40
                  }

                  if (key === 2) {
                    deg = 33
                  }

                  return (
                    <StyledBoxDefaultOutline
                      noCorner
                      white
                      className="my-3"
                      key={blok.title}
                    >
                      <div className="border-right" />
                      <div className="border-bottom" />
                      <div
                        className="border-diagonal"
                        style={{
                          transform: `rotate(-${deg}deg)`,
                        }}
                      />
                      <h3 className="text-white text-uppercase font-family-secondary font-size-xxl">
                        {blok.title}
                      </h3>
                      <ParseContent content={blok.description} />
                      {key + 1 === 1 && <img src={number1} alt="" />}
                      {key + 1 === 2 && <img src={number2} alt="" />}
                      {key + 1 === 3 && <img src={number3} alt="" />}
                    </StyledBoxDefaultOutline>
                  )
                })}
              </Identiteit>
            </div>
          </div>
        </div>
      </section>

      <section className="container mb-5 pb-4">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <Faq className="d-flex flex-column align-items-center color-background-secondary mt-n5 p-lg-5 p-4 shadow">
              <h2 className="mb-lg-5 mb-3 text-center">{vragen.title}</h2>

              <Questions questIds={vragen.questions} />

              <ButtonDefaultOutline
                className="special-button"
                to={vragen.link.url}
              >
                {vragen.link.title}
              </ButtonDefaultOutline>
            </Faq>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default HomeTemplate
