/* eslint-disable react/function-component-definition */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import HomeTemplate from '../templates/home'

const HomePage = ({ data }) => <HomeTemplate data={data} />

export const rootQuery = graphql`
  query ($id: String) {
    page: wpPage(id: { eq: $id }, template: { templateName: { eq: "Home" } }) {
      ...HomeFrag
    }
  }
`

export default HomePage
